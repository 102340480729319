<!-- 口說測驗答題進度 -->
<template>
  <div class="TopicReciteProgress">
    <!-- 左側題號 -->
    <div class="TopicHearingProgress-left">
      <div class="TopicHearingProgress-left-title">
        <div class="title-h1">
          作答進度
        </div>
        <div class="title-part">
          <div v-for="(item, index) in list" :key="index" @click="partClick(index)">
            <div v-if="partActive == index">
              <div class="part-div type2">
                Part {{ item.part_sort }}
              </div>
            </div>
            <div v-else>
              <div v-if="item.isAnswer == 0" class="part-div">
                Part {{ item.part_sort }}
              </div>
              <div v-else class="part-div type1">
                Part {{ item.part_sort }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="TopicHearingProgress-left-schedule">
        <div class="schedule-title">
          <img src="@/assets/image/icon/icon-schedule.png" alt="">
          {{currentProgress}}<span>/{{totalProgress}}</span>
        </div>
        <div class="schedule-topic">
          <div v-for="(item, index) in list[partActive].question_group" :key="index" @click="topicClick(index)">
            <div v-if="`${partActive}-${index}` == topicActive && testActive == 0">
              <div class="topic-div type2">
                <!-- {{ item.progressNum }} -->
                {{ item.group_title }}
              </div>
            </div>
            <div v-else>
              <div
                v-if="item.isAnswer == 0"
                class="topic-div"
              >
                <!-- {{ item.progressNum }} -->
                {{ item.group_title }}
              </div>
              <div
                v-else
                class="topic-div type1"
              >
                <!-- {{ item.progressNum }} -->
                {{ item.group_title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右側提交按鈕 -->
    <div class="TopicHearingProgress-right">
      <button class="right-button1" v-if="butStatus == 2" @click="deliverOpen">
        口說測驗交卷
        <img src="@/assets/image/icon/icon-next.png" alt="">
      </button>
      <button class="right-button2" v-if="butStatus == 1" @click="nextClick">
        NEXT
        <img src="@/assets/image/icon/icon-next.png" alt="">
      </button>
    </div>

    <!-- 交卷彈窗 -->
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="app-popup" v-show="deliverShow">
        <transition
          enter-active-class="animated zoomIn"
          leave-active-class="animated zoomOut"
        >
          <div class="deliver-popup" v-if="deliverShow">
            <div class="deliver-popup-h1">
              <img src="@/assets/image/icon/icon-deliver.png" alt="">
              確定交卷？
            </div>
            <div class="deliver-popup-h2">
              <p>-口說測驗-</p>
              <span></span>
            </div>
            <div class="deliver-popup-h3">
              口說測驗沒辦法修改答案了唷！請按下<span>「確定交卷」</span>完成測驗。
            </div>
            <div class="deliver-popup-btn">
              <div class="button button1" @click="deliverCancel">取消</div>
              <div class="button button2" @click="deliverSubmit">確定交卷</div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- 考試時間結束彈窗 -->
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="app-popup" v-show="examOverShow">
        <transition
          enter-active-class="animated zoomIn"
          leave-active-class="animated zoomOut"
        >
          <div class="timeOver-popup" v-if="examOverShow">
            <h1 class="timeOver-popup-h1">口說測驗時間結束</h1>
            <div class="timeOver-popup-line"></div>
            <img class="timeOver-popup-img" src="@/assets/image/examination/deliver3.png" alt="">
            <h2 class="timeOver-popup-h2">別擔心！你的答案我們都收到了！</h2>
            <p class="timeOver-popup-p">畫面將在{{ examCountDown }}秒後自動關閉</p>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from "vuex"
import { questionFinish } from "@/request/api"
export default {
  name: 'TopicReciteProgress',
  props: {
    // 数据列表
    list: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      // 题目类型  1=聽力測驗，2=閱讀測驗，3=寫作測驗，4=口說能力測驗
      type: 4,

      // 作答進度-当前进度
      currentProgress: 1,
      // 作答進度-总长度
      totalProgress: 0,

      // 交卷-彈窗狀態
      deliverShow: false,

      // 考試時間結束-彈窗狀態
      examOverShow: false,
      // 考試時間結束-彈窗倒計時 實際時間+1
      examCountDown: 4,

      // 提交按鈕-顯示狀態 1不展示 2展示完成測驗
      butStatus: 1,

      // 題目切換-部分选中
      partActive: 0,
      // 題目切換-题目选中
      topicActive: '0-0',
      // 題目切換-部分试题狀態
      testActive: 1,
      // 題目切換-答題狀態 0還沒跳到最後一題  1跳到了最後一題
      answerStatus: 0,
    }
  },
  computed: {
    ...mapGetters(['examAuestionSort']),
  },
  watch: {
    // 深度监听数组变化
    list: {
      handler () {
        // 总题目长度
        this.totalProgress = this.list.at(-1).question_group.at(-1).progressNum
      },
      deep: true,  // 可以深度检测到 obj 对象的属性值的变化
      immediate: true //刷新加载  立马触发一次handler
    },
  },
  created() {
    if (this.examAuestionSort && this.examAuestionSort.length > 3) {
      let [val1, val2, val3] = this.examAuestionSort.split('&')
      this.partActive = val1
      this.topicActive = `${val1}-${val2}`
      this.testActive = val3

      
      const pratLength = this.list.length - 1
      const partActive = val1
      const topicLengtn = this.list[partActive].question_group.length - 1
      const topicActive = val2
      // 按钮切换
      if (partActive == pratLength && topicActive == topicLengtn && this.testActive == 0) {
        this.butStatus = 2
      }
      
      // 当前选中题目回显
      this.currentProgress = this.list[partActive]?.question_group[topicActive]?.progressNum || 1
    }
  },
  methods: {
    // 判断是不是最后一题
    isFinally() {
      const pratLength = this.list.length - 1
      const partActive = this.partActive
      const topicLengtn = this.list[partActive].question_group.length - 1
      const topicActive = this.topicActive.split('-')?.[1]
      // 按钮切换
      // if (partActive == pratLength && topicActive == topicLengtn && this.testActive == 0) {
      //   this.butStatus = 2
      // }

      // 当前选中题目回显
      this.currentProgress = this.list[partActive]?.question_group[topicActive]?.progressNum
      // 回调事件  切换题型
      this.$emit('progressChange', {
        topicActive: this.topicActive,
        testActive: this.testActive
      })
    },
    // 部位选中
    partClick(index) {
      // return false
      // if (this.butStatus == 1) return
      // 部位選中
      this.partActive = index
      // 題目選中第一題
      this.topicActive = `${index}-0`
      // 試題類型選中
      this.testActive = 1
      // 按钮切换
      this.isFinally()
    },
    // 题目选中
    topicClick(index) {
      // return false
      // if (this.butStatus == 1) return
      // 題目選中當前題
      this.topicActive = `${this.partActive}-${index}`
      // 試題類型置空
      this.testActive = 0

      console.log(this.topicActive)
      // 按钮切换
      this.isFinally()
    },
    // 防抖處理
    nextClick: debounce(function() {
      this.next()
    }, 300),
    // 下一题
    next() {
      // 部位長度
      const pratLength = this.list.length - 1
      // 部位當前選中索引
      const partActive = Number(this.partActive)
      // 題目長度
      const topicLengtn = this.list[partActive]?.question_group.length - 1
      // 題目當前選中索引
      const topicActive = this.topicActive.split('-')?.[1]
      
      // 下一步
      if (topicActive >= topicLengtn) { // 是当前部分的最后一小题
        if (this.testActive != 0) { // 如果不等于0的话就是 示例的下一步
          this.testActive = 0
        } else {
          if (partActive >= pratLength) { // 是最后一部分
            this.butStatus = 2
            this.$emit('progressFinally')
            return
          } else { // 不是最后一部分
            // 部位增加並選中
            this.partActive = partActive + 1
            // 試題類型選中
            this.testActive = this.partActive
            // 選中部位加1的下標為0的組件
            this.topicActive = `${this.partActive}-0`
          }
        }
      } else { // 不是当前部分的最后一小题
        if (this.testActive != 0) { // 如果不等于0的话就是 示例的下一步
          this.testActive = 0
          this.topicActive = `${partActive}-0`
        } else {
          this.topicActive = `${partActive}-${Number(topicActive) + 1}`
        }
      }
      
      // 按钮切换
      this.isFinally()
    },

    // 更改按钮状态
    butSet() {
      this.butStatus = 2
    },

    // 打開交卷彈窗
    deliverOpen() {
      this.deliverShow = true
    },
    // 取消交卷
    deliverCancel() {
      this.deliverShow = false
    },
    // 確定交卷
    deliverSubmit() {
      // questionFinish({
      //   type: this.type,
      // }).then(() => {
      //   this.$router.push({
      //     path: '/examinationOver'
      //   })
      // })
      this.$router.push({
        path: '/examinationOver'
      })
    },

    // 打开考试到期弹窗
    async examOverOpen() {
      this.examOverShow = true
      this.countTime()
      // await questionFinish({ type: this.type }).then(() => {})
    },

    // 倒計時
    countTime() {
      if (this.examCountDown > 0) {
        this.examCountDown -= 1
        setTimeout(this.countTime, 1000)
      } else {
        this.examOverShow = false
        // this.$router.push({
        //   path: '/examinationOver'
        // })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.TopicReciteProgress {
  padding: 12px 30px 12px 20px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .TopicHearingProgress-left {
    width: calc(100% - 300px);
    .TopicHearingProgress-left-title {
      display: flex;
      align-items: center;
      .title-h1 {
        width: 88px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #333333;
      }
      .title-part {
        width: calc(100% - 88px);
        display: flex;
        .part-div {
          user-select: none;
          cursor: pointer;
          margin-right: 10px;
          padding: 7px 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px 4px 4px 0px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          background: #FFFFFF;
          border: 1px solid #D0D4DB;
          color: #333333;
        }
        .type1 {
          background: #E5EBF3;
          border: 1px solid #E5EBF3;
          color: #000000;
        }
        .type2 {
          background: #0C4FA2;
          border: 1px solid #0C4FA2;
          color: #FFFFFF;
        }
      }
    }
    .TopicHearingProgress-left-schedule {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .schedule-title {
        width: 88px;
        display: flex;
        align-items: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        img {
          margin-right: 4px;
          width: 16px;
          height: 16px;
        }
        span {
          font-size: 13px;
          line-height: 19px;
          color: #989898;
        }
      }
      .schedule-topic {
        display: flex;
        .topic-div {
          user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          width: 36px;
          height: 36px;
          background: #FFFFFF;
          border: 1px solid #D0D4DB;
          border-radius: 50%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }
        .type1 {
          background: #E5EBF3;
          border: 1px solid #E5EBF3;
          color: #000000;
        }
        .type2 {
          background: #0C4FA2;
          border: 1px solid #0C4FA2;
          color: #FFFFFF;
        }
      }
    }
  }
  .TopicHearingProgress-right {
    .right-button1 {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 282px;
      height: 60px;
      background: #FFC121;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      border: none;
    }
    
    .right-button2 {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 60px;
      background: #0C4FA2;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      border: none;
    }
  }

  // 鼠標禁用按鈕
  .cursor-not {
    cursor: not-allowed !important;
  }

  // 交卷彈窗
  .deliver-popup {
    width: 580px;
    height: 513px;
    background-image: url('../../../../assets/image/examination/deliver1.png');
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
    .deliver-popup-h1 {
      margin-top: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: #FFFFFF;
      img {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }
    }
    .deliver-popup-h2 {
      margin: 57px 0 22px;
      display: inline-flex;
      position: relative;
      p {
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #333333;
        z-index: 2;
      }
      span {
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 130px;
        height: 9px;
        background: #C8D8FC;
        z-index: 1;
      }
    }
    .deliver-popup-h3 {
      margin: 0 auto;
      width: 416px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #666666;
      text-align: left;
      span {
        color: #0C4FA2;
      }
    }
    .deliver-popup-btn {
      margin-top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        cursor: pointer;
        width: 210px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        border-radius: 35px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
      }
      .button1 {
        border: 2px solid rgba(255, 255, 255, 0.7);
        color: #FFFFFF;
      }
      .button2 {
        color: #1064CE;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 1px rgba(32, 80, 228, 0.6), inset 0px 0px 10px 5px rgba(3, 74, 255, 0.3);
      }
    }
  }

  // 考試時間結束彈窗
  .timeOver-popup {
    padding: 40px 5px;
    width: 570px;
    background: #FFFFFF;
    border-radius: 12px;
    text-align: center;
    .timeOver-popup-h1 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: #333333;
    }
    .timeOver-popup-line {
      margin: 24px auto;
      width: 100%;
      height: 1px;
      background: #EAEAEA;
    }
    .timeOver-popup-img {
      width: 367px;
      height: 250px;
    }
    .timeOver-popup-h2 {
      margin: 30px 0 20px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 35px;
      text-align: center;
      color: #333333;
    }
    .timeOver-popup-p {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #989898;
    }
  }
}
</style>