<!-- 口說測驗題目及答案 -->
<template>
  <div class="TopicReciteAnswer">
    <!-- 題型title -->
    <h1 class="TopicReciteAnswer-h1">
      {{ dataForm.group_title }}. 口說（{{ partTitle }}）
      <!-- {{ dataForm.progressNum }}. 口說（{{ partTitle }}） -->
    </h1>
    <div class="TopicReciteAnswer-box">
      <!-- 題目-STA -->
      <div class="box-left">
        <!-- 题目类型 1   音頻 -->
        <div class="audio topic1" ref="topicRef1" v-if="[3].includes(topicType)">
          <div class="audio-title">
            <img src="@/assets/image/icon/icon-hear.png" alt="">
            請聽題
          </div>
          <div class="audio-content">
            <div class="audio-content-div" :class="playStatus ? 'audio-content-div-active' : ''">
              <div class="div-left">
                <img v-if="playStatus" src="@/assets/image/icon/icon-plays.png" alt="">
                <img v-if="!playStatus" src="@/assets/image/icon/icon-plays-dis.png" alt="">
              </div>
              <div class="div-right">
                <div class="div-right-time">
                  <span>{{playCurrentTime}}</span>
                  <span>{{playDuration}}</span>
                </div>
                <div class="div-right-progress">
                  <el-slider v-model="playSchedule" :show-tooltip="false" disabled></el-slider>
                  <!-- <div class="progress-round" :style="{ left: playSchedule + '%' }"></div>
                  <el-progress :percentage="playSchedule" v-if="!isNaN(parseInt(playSchedule))" :show-text="false"></el-progress> -->
                  <audio ref="singeBox"></audio>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 题目类型 2   文+图 -->
        <div class="topic2" ref="topicRef2" v-if="[1, 2, 4].includes(topicType)">
          <div class="left-txt" v-if="[1, 4].includes(topicType)">
            <!-- {{ topicData.content }} -->
            <div v-html="contentHandel(topicData.content)"></div>
          </div>
          <img class="left-img" v-if="[2, 4].includes(topicType)" :src="topicData.image_uri" alt="">
        </div>
        
        <!-- 遮罩層 -->
        <!-- <div class="left-mask" v-if="[1, 2, 4].includes(topicType)"></div> -->
      </div>
      <!-- 題目-END -->

      <!-- 答案-STA -->
      <div class="box-right">
        <!-- 答案类型 10秒 15秒录音 -->
        <div class="audio answer1" ref="answerRef1" v-if="[4, 5].includes(answerType)">
          <div class="audio-title">
            <img src="@/assets/image/icon/icon-hears.png" alt="">
            請作答
          </div>
          <div class="audio-content">
            <div class="audio-content-div" :class="recordStatus ? 'audio-content-div-active' : ''">
              <div class="div-left">
                <img v-if="recordStatus" src="@/assets/image/icon/icon-stops.png" alt="">
                <img v-if="!recordStatus" src="@/assets/image/icon/icon-stops-dis.png" alt="">
              </div>
              <div class="div-right">
                <div class="div-right-time">
                  <span>{{ recordCurrentTime }}</span>
                  <span>{{ recordDuration }}</span>
                </div>
                <div class="div-right-progress">
                  <el-slider v-model="recordSchedule" :show-tooltip="false" disabled></el-slider>
                  <!-- <div class="progress-round" :style="{ left: recordSchedule + '%' }"></div>
                  <el-progress :percentage="recordSchedule" :show-text="false"></el-progress> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 答案类型 60秒录音 -->
        <div class="answer2" ref="answerRef2" v-if="[6].includes(answerType)">
          <div class="right-countDown">
            <img src="@/assets/image/icon/icon-alarm.png" alt="">
            <div>
              閱讀時間剩餘:
              <span>{{ readDuration.min }}</span>
              :
              <span>{{ readDuration.sec }}</span>
            </div>
          </div>
          <audio v-show="false" ref="readAloudRef"></audio>
          <div class="audio">
            <div class="audio-title">
              <img src="@/assets/image/icon/icon-hears.png" alt="">
              請作答
            </div>
            <div class="audio-content">
              <div class="audio-content-div" :class="recordStatus ? 'audio-content-div-active' : ''">
                <div class="div-left">
                  <img v-if="recordStatus" src="@/assets/image/icon/icon-stops.png" alt="">
                  <img v-if="!recordStatus" src="@/assets/image/icon/icon-stops-dis.png" alt="">
                </div>
                <div class="div-right">
                  <div class="div-right-time">
                    <span>{{ recordCurrentTime }}</span>
                    <span>{{ recordDuration }}</span>
                  </div>
                  <div class="div-right-progress">
                    <el-slider v-model="recordSchedule" :show-tooltip="false" disabled></el-slider>
                    <!-- <div class="progress-round" :style="{ left: recordSchedule + '%' }"></div>
                    <el-progress :percentage="recordSchedule" :show-text="false"></el-progress> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 答案-END -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fileUpload } from "@/request/api"
// import lamejs from 'lamejstmp'
// import Recorder from 'js-audio-recorder'
// import Recorder from 'recorder-core';
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
// import 'recorder-core/src/engine/mp3';
// import 'recorder-core/src/engine/mp3-engine';
import readAloud from "@/assets/audio/recite/readAloud.mp3";
export default {
  name: 'TopicReciteAnswer',
  props: {
    partTitle: {
      type: String,
      default: ''
    },
    dataForm: {
      type: Object,
      default: () => {}
    },
    partActive: {
      type: String,
      default: '0'
    },
    topicActive: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      // 播放音频-实例对象
      playAudio: '',
      // 播放音频-播放状态
      playStatus: false,
      // 播放音频-展示总时长
      playDuration: '00:00',
      // 播放音频-展示播放时长
      playCurrentTime: '00:00',
      // 播放音频-展示進度
      playSchedule: 0,

      // 录制音频-答案录制时长 10s
      recordTime1: 10,
      // 录制音频-答案录制时长 15s
      recordTime2: 15,
      // 录制音频-答案录制时长 60s
      recordTime3: 60,
      // 录制音频-实例对象
      recordAudio: '',
      // 录制音频-录制状态
      recordStatus: false,
      // 录制音频-展示总时长
      recordDuration: '00:00',
      // 录制音频-展示播放时长
      recordCurrentTime: '00:00',
      // 录制音频-展示進度
      recordSchedule: 0,
      // 录制音频-定时器
      recordTimerOut: null,
      
      // 题型2 倒计时的时间
      readDuration: {},

      // 題目類型  1文字  2图片  3音频  4文字+图片  5音频+图片
      topicType: 1,
      // 答案類型  1选项  2文本不限字数  3文本限字数50  4录音10秒  5录音15秒  6录音60秒
      answerType: 1,
      // 題目數據
      topicData: {},

      // 朗讀中間的音頻
      readAloudAudio: '',

      // 錄音開始時間 用於計算倒計時
      recordStartTime: 0,
      
      // 播放狀態
      recorderPlaySuatus: false,
      recordingDuration: 0,
      totalRecordingDuration : 0,
    }
  },
  computed: {
    ...mapGetters(['problemPopupStatus', 'admissionTicket']),
  },
  watch: {
    dataForm: {
      handler() {
        // 初始化的時候根據判斷渲染數據
        if (this.dataForm.group_type == 1) { // 1單體形式  2題組形式
          const data = this.dataForm.question?.[0]
          // 數據賦值
          this.topicData = data
          // 題目類型賦值
          this.topicType = data.show_type
          // 答案類型賦值
          this.answerType = data.answer_type
        } else {
          
        }
      },
      deep: true,
      immediate: true
    },
    
    // 監聽問題回報彈窗 同步暫停/開啟音頻
    problemPopupStatus(val) {
      if (val) {
        // 暂停播放音频
        if (this.playStatus) {
          this.playAudio.pause()
        }
        // 暂停录音
        if (this.recordStatus) {
          // this.recordAudio.pause()
          this.recordingDuration = this.totalRecordingDuration
          this.recorderPlaySuatus = true
        }
        // 暂停阅读倒计时
        clearInterval(this.recordTimerOut);
        this.recordTimerOut = null;
      } else {
        // 继续播放音频
        if (this.playStatus) {
          this.playAudio.play()
        }
        // 继续录音
        if (this.recordStatus) {
          // this.recordAudio.resume()
          this.recorderPlaySuatus = false
          this.recordStartTime = Date.now()
          this.recordCountTime()
        }
        // 继续阅读倒计时
        if (!this.recordStatus) {
          this.readCountTime()
        } 
      }
    }
  },
  methods: {
    // 播放音频
    init() {
      this.recordDuration = '00:00'
      this.recordCurrentTime = '00:00'
      this.recordSchedule = 0
    
      // 禁用按钮
      this.recordStatus = false
      this.recorderPlaySuatus = true
      // 時間置空
      this.recordStartTime = 0
      this.totalRecordingDuration = 0
      this.recordingDuration = 0

      if (this.answerType == 6) {
        // 初始化录音总时长
        const duration = this.formatTime(this.recordTime3)
        this.recordDuration = `${duration.min}:${duration.sec}`
        this.recordCountDown = this.recordTime3
        // 阅读时间+1秒
        this.recordTime3 += 1
        // 调用阅读倒计时
        this.readCountTime()
      } else if (this.answerType == 4) {
        // 初始化录音总时长
        const duration = this.formatTime(this.recordTime1)
        this.recordDuration = `${duration.min}:${duration.sec}`
        this.recordCountDown = this.recordTime1

        // 播放音频
        this.audioInit()
        this.$nextTick(() => {
          this.playAudio.play()
          this.playStatus = true
        })
      } else if (this.answerType == 5) {
        // 初始化录音总时长
        const duration = this.formatTime(this.recordTime2)
        this.recordDuration = `${duration.min}:${duration.sec}`
        this.recordCountDown = this.recordTime2

        // 播放音频
        this.audioInit()
        this.$nextTick(() => {
          this.playAudio.play()
          this.playStatus = true
        })
      }
    },
    
    // 頁面滾動到頂部
    toRoll() {
      // 初始化的时候答案區塊滚动条置顶
      if (this.$refs.answerRef1) this.$refs.answerRef1.scrollTop = 0
      if (this.$refs.answerRef2) this.$refs.answerRef2.scrollTop = 0
      // 初始化的时候問題區塊滚动条置顶
      if (this.$refs.topicRef1) this.$refs.topicRef1.scrollTop = 0
      if (this.$refs.topicRef2) this.$refs.topicRef2.scrollTop = 0
    },

    // 阅读倒计时
    readCountTime() {
      if (this.recordTime3 > 0) {
        this.recordTime3--
        this.readDuration = this.formatTime(this.recordTime3)
        // setTimeout(this.readCountTime, 1000)
        // 重复调用倒计时
        this.recordTimerOut = window.setTimeout(this.readCountTime, 1000)
      } else {
        // this.recordStart()
        this.readAloudInit()
        this.$nextTick(() => {
          this.readAloudAudio.play()
        })
      }
    },
    // // 开始录音
    // recordStart2() {
    //   this.recordStatus = true
    //   this.recordAudio = new Recorder()
    //   Recorder.getPermission().then(() => {
    //     // 开始录音
    //     this.recordAudio.start()
    //     // 开启录音倒计时
    //     this.recordCountTime2()
    //   }, (error) => {
    //     this.$message({
    //       message: '請允許該網頁使用麥克風',
    //       type: 'info'
    //     })
    //   })
    // },
    // // 录音倒计时
    // recordCountTime2() {
    //   // 进度条处理
    //   let schedule = Number(this.recordAudio.duration / this.recordCountDown * 100)
    //   this.recordSchedule = schedule >= 100 ? 100 : schedule
    //   // 倒计时
    //   const current = this.formatTime(Math.floor(this.recordAudio.duration))
    //   this.recordCurrentTime = `${current.min}:${current.sec}`

    //   // 判断播放总时间和录制进度时间
    //   if (this.recordAudio.duration >= this.recordCountDown) {
    //     // 停止录音
    //     this.recordAudio.stop()
    //     // 禁用按钮
    //     this.recordStatus = false

    //     // 跳转下一题
    //     this.$emit('topicChange', '3')

    //     // 文件转换
    //     const wavBlob = this.recordAudio.getWAVBlob()
    //     const file = new window.File([wavBlob], 'name.wav', {type: 'audio/wav'}) // 转成file类型
    //     console.log(file)
    //     console.log(file.size)
    //   } else {
    //     setTimeout(this.recordCountTime2, 100)
    //   }
    // },


    // 播放音频初始化
    audioInit() {
      this.playAudio = this.$refs.singeBox
      this.playAudio.src = this.topicData.audio_uri

      const _that = this
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.playAudio.ondurationchange = function() {
        _that.audioInitUpdateTime()
      }
      // 播放位置发送改变时触发。
      this.playAudio.ontimeupdate = function() {
        _that.audioInitUpdateTime()
      }
      // 音频播放完毕
      this.playAudio.onended = function() {
        _that.playStatus = false
        _that.recordStart()
      }
    },
    // 更新时间
    audioInitUpdateTime() {
      const total = this.formatTime(this.playAudio.duration)
      const current = this.formatTime(this.playAudio.currentTime)
      this.playDuration = `${total.min}:${total.sec}`
      this.playCurrentTime = `${current.min}:${current.sec}`
      this.playSchedule = Number(this.playAudio.currentTime / this.playAudio.duration * 100)
    },

    // 播放閱讀之間的音頻
    readAloudInit() {
      const _that = this
      this.readAloudAudio = this.$refs.readAloudRef
      this.readAloudAudio.src = readAloud
      this.readAloudAudio.onended = function() {
        _that.recordStart()
      }
    },

    // 开始录音
    recordStart() {
      this.recordStatus = true
      this.recorderPlaySuatus = false
      // 开启录音倒计时
      this.recordStartTime = Date.now()
      this.recordCountTime()
      // const newRec = Recorder({
      //   type: 'mp3',
      //   bitRate: 16,
      //   sampleRate: 16000, //阿里采样率16000
      //   onProcess: function (buffers, powerLevel, duration, bufferSampleRate) {
      //     // console.log(buffers);
      //   },
      // })
      // newRec.open(() => {
      //   this.recordAudio = newRec;
      //   // 开始录音
      //   this.recordAudio.start()
      //   this.recordStartTime = Date.now()
      //   // 开启录音倒计时
      //   this.recordCountTime()
      // }, () => {
      //   this.$message({
      //     message: '請允許該網頁使用麥克風',
      //     type: 'info'
      //   })
      // })

      // this.recordAudio = new Recorder()
      // Recorder.getPermission().then(() => {
      //   // 开始录音
      //   this.recordAudio.start()
      //   // 开启录音倒计时
      //   this.recordCountTime()
      // }, (error) => {
      //   this.$message({
      //     message: '請允許該網頁使用麥克風',
      //     type: 'info'
      //   })
      // })
    },
    // 录音倒计时
    async recordCountTime() {
      if (this.recorderPlaySuatus) {
        return false
      }
      let currentTime = Date.now()
      let countdown = ((currentTime - this.recordStartTime) / 1000) + this.recordingDuration
      this.totalRecordingDuration = countdown
      // 进度条处理
      let schedule = Number(countdown / this.recordCountDown * 100)
      this.recordSchedule = schedule >= 100 ? 100 : schedule
      
      // 倒计时
      const current = this.formatTime(Math.floor(countdown))
      this.recordCurrentTime = `${current.min}:${current.sec}`
      
      // 判断播放总时间和录制进度时间
      if (countdown - 0.2 >= this.recordCountDown) {
        // 禁用按钮
        this.recordStatus = false
        // 時間置空
        this.recordStartTime = 0
        this.totalRecordingDuration = 0
        this.recordingDuration = 0
        this.$nextTick(() => {
          // 答案赋值
          this.$emit('answerChange', 'res.data.url')
          // 跳转下一题
          this.$emit('playChange')
        })
      } else {
        setTimeout(this.recordCountTime, 100)
      }
      // let currentTime = Date.now()
      // let countdown = ((currentTime - this.recordStartTime) / 1000) + this.recordingDuration
      // this.totalRecordingDuration = countdown
      // // 进度条处理
      // let schedule = Number(countdown / this.recordCountDown * 100)
      // this.recordSchedule = schedule >= 100 ? 100 : schedule
      
      // // 倒计时
      // const current = this.formatTime(Math.floor(countdown))
      // this.recordCurrentTime = `${current.min}:${current.sec}`

      // // 判断播放总时间和录制进度时间
      // if (countdown - 0.2 >= this.recordCountDown) {
      //   // 停止录音
      //   // this.recordAudio.stop()
      //   // 禁用按钮
      //   this.recordStatus = false

        
      //   this.recordAudio.stop(async (blob, duration) => {
      //     console.log('录音成功', blob, '时长:' + duration + 'ms');
      //     // 停止錄音
      //     this.recordAudio.close();
      //     this.recordAudio = null;

      //     let formData = new FormData()
      //     formData.append('file', blob)
      //     let partActive = Number(this.partActive) + 1
      //     let topicActive = Number(this.topicActive) + 1
      //     formData.append('file_name', `${this.admissionTicket}_${partActive}_${topicActive}`)
      //     await fileUpload(formData).then(res => {
      //       // 時間置空
      //       this.recordStartTime = 0
      //       this.totalRecordingDuration = 0
      //       this.recordingDuration = 0
      //       this.$nextTick(() => {
      //         // 答案赋值
      //         this.$emit('answerChange', res.data.url)
      //         // 跳转下一题
      //         this.$emit('playChange')
      //       })
      //     })
      //   });
      //   // let file = this.convertToMp3(this.recordAudio.getWAV())
      //   // file = new File([file], '考生音頻.mp3')
      //   // 文件转换
      //   // const wavBlob = this.recordAudio.getWAVBlob()
      //   // const file = new window.File([wavBlob], '考生音頻.mp3', {type: 'audio/mp3'}) // 转成file类型
      //   // console.log('----------------------------')
      //   // console.log(file)
      //   // console.log('----------------------------')
      //   // const file = new window.File([wavBlob], '考生音頻.wav', {type: 'audio/wav'}) // 转成file类型

      // } else {
      //   setTimeout(this.recordCountTime, 100)
      // }
    },

    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      console.log('wav', wav)
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = this.recordAudio.getChannelData()
      const buffer = [];
    
      const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);
    
      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;
    
        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }
    
        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }
    
      const enc = mp3enc.flush();
    
      if (enc.length > 0) {
        buffer.push(enc);
      }
      return new Blob(buffer, { type: 'audio/mp3' });
    },

    // 格式化毫秒，返回String型分秒对象
    formatTime(time) {
      // 有可能没获取到，为NaN
      if (!time) return { min: '00', sec: '00' }
      return {
        min: Math.floor(time / 60).toString().padStart(2, '0'),
        sec: Math.floor(time % 60).toString().padStart(2, '0')
      }
    },

    // 數據處理 文字增加行距
    contentHandel(text) {
      let str = text.replaceAll('\n', '<div style="height: 10px;"></div>')
      return str
    }
  }
}
</script>

<style lang="less" scoped>
.TopicReciteAnswer {
  width: 100%;
  height: 100%;
  .TopicReciteAnswer-h1 {
    padding-bottom: 20px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #333333;
  }
  .TopicReciteAnswer-box {
    width: 100%;
    height: calc(100% - 55px);
    overflow: auto;
    display: flex;
    justify-content: space-between;
    .audio {
      width: 100%;
      height: 100%;
      background: #F5F7FD;
      border-radius: 8px;
      overflow: hidden;
      .audio-title {
        padding: 0 20px;
        width: calc(100% - 40px);
        height: 65px;
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-size: 100%;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 35px;
        color: #FFFFFF;
        img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .audio-content {
        padding: 70px 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .audio-content-div {
          padding: 28px 16px;
          width: calc(100% - 32px);
          background: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          .div-left {
            margin-right: 16px;
            width: 54px;
            height: 54px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .div-right {
            width: calc(100% - 70px);
            .div-right-time {
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: #4F4F4F;
            }
            .div-right-progress {
              width: 100%;
              height: 12px;
              position: relative;
              .progress-round {
                position: absolute;
                top: -2px;
                width: 16px;
                height: 16px;
                z-index: 1;
                transform: translateX(-50%);
                border-radius: 50%;
                background: #4277FF;
                box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
              }
              /deep/ .el-progress-bar__outer {
                height: 12px !important;
                background-color: #D3D3D3;
              }
              /deep/ .el-progress-bar__inner {
                background-color: unset;
                background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
              }
              /deep/ .el-slider__runway {
                margin: 0;
                width: 100%;
                height: 12px;
                background-color: #D3D3D3;
                border-radius: 12px;
                .el-slider__bar {
                  width: 100%;
                  height: 100%;
                  border-radius: 12px;
                  background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
                }
              }
              /deep/ .el-slider__button-wrapper {
                margin-top: 11px;
                width: 16px;
                height: 16px;
                z-index: 2;
                // border-radius: 50%;
                // background: #4277FF;
                // box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                .el-slider__button {
                  width: 16px;
                  height: 16px;
                  border: none;
                  background: #4277FF;
                  box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                }
              }
            }
          }
        }
        .audio-content-div-active {
          border: 1px solid #3774E4;
        }
      }
    }
    .box-left {
      width: calc(50% - 10px);
      height: 100%;
      position: relative;
      .left-mask {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: 0px 0px 0px 4px;
        pointer-events: none;
      }
      .audio {
        .audio-title {
          background-image: url('../../../../assets/image/examination/hearing3.png');
        }
      }
    }
    .box-right {
      width: calc(50% - 10px);
      height: 100%;
      .audio {
        .audio-title {
          background-image: url('../../../../assets/image/examination/hearing4.png');
        }
      }
    }
    .topic1 {
      height: 100%;
    }
    .topic2 {
      width: calc(100% - 30px);
      height: calc(100% - 35px);
      padding: 15px 15px 20px;
      background: #F7F8FB;
      border-radius: 4px;
      .left-img {
        max-width: 100%;
      }
      .left-txt {
        width: 100%;
        height: 100%;
        overflow: auto;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #333333;
        white-space: pre-line;
      }
    }
    .answer1 {
      width: 100%;
      height: 100%;
    }
    .answer2 {
      width: 100%;
      height: 100%;
      .right-countDown {
        padding-left: 35px;
        width: calc(100% - 35px);
        height: 65px;
        background-image: url('../../../../assets/image/examination/recite2.png');
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
          width: 30px;
          height: 30px;
        }
        div {
          display: flex;
          align-items: center;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #0C4FA2;
          span {
            margin: 0 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: #3774E4;
            border-radius: 4px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }
      .audio {
        margin-top: 16px;
        width: 100%;
        height: calc(100% - 81px);
        background: #F5F7FD;
        border-radius: 8px;
        overflow: hidden;
        .audio-title {
          padding: 0 20px;
          width: calc(100% - 40px);
          height: 65px;
          display: flex;
          align-items: center;
          background-repeat: no-repeat;
          background-size: 100%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 35px;
          color: #FFFFFF;
          img {
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
        }
        .audio-content {
          padding: 70px 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .audio-content-div {
            padding: 28px 16px;
            width: calc(100% - 32px);
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            .div-left {
              margin-right: 16px;
              width: 54px;
              height: 54px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .div-right {
              width: calc(100% - 70px);
              .div-right-time {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: "Microsoft JhengHei","微軟正黑體";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #4F4F4F;
              }
              .div-right-progress {
                width: 100%;
                height: 12px;
                position: relative;
                .progress-round {
                  position: absolute;
                  top: -2px;
                  width: 16px;
                  height: 16px;
                  z-index: 1;
                  transform: translateX(-50%);
                  border-radius: 50%;
                  background: #4277FF;
                  box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                }
                /deep/ .el-progress-bar__outer {
                  height: 12px !important;
                  background-color: #D3D3D3;
                }
                /deep/ .el-progress-bar__inner {
                  background-color: unset;
                  background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
                }
                /deep/ .el-slider__runway {
                  margin: 0;
                  width: 100%;
                  height: 12px;
                  background-color: #D3D3D3;
                  border-radius: 12px;
                  .el-slider__bar {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
                  }
                }
                /deep/ .el-slider__button-wrapper {
                  margin-top: 11px;
                  width: 16px;
                  height: 16px;
                  z-index: 2;
                  // border-radius: 50%;
                  // background: #4277FF;
                  // box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                  .el-slider__button {
                    width: 16px;
                    height: 16px;
                    border: none;
                    background: #4277FF;
                    box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
                  }
                }
              }
            }
          }
          .audio-content-div-active {
            border: 1px solid #3774E4;
          }
        }
      }
    }
  }
}
</style>